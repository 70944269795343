<template>
    <div class="item" :id="detial.uuid" :style="{height: 'calc( 100% - ' + detial.itemStyle.interval + 'px )',borderWidth: detial.itemStyle.borderWidth + 'px',borderStyle: detial.itemStyle.borderStyle,borderColor: detial.itemStyle.borderColor,padding: detial.itemStyle.paddingVertical + 'px ' + detial.itemStyle.paddingHorizontal + 'px',marginBottom: detial.itemStyle.interval + 'px',borderRadius: detial.itemStyle.borderRadius + 'px',backgroundColor: detial.itemStyle.backgroundColor}">
        <div class="label" v-show="detial.showLabel" :style="{width: detial.labelStyle.width + 'px',textAlign: detial.labelStyle.textAlign == '居左'?'left': detial.labelStyle.textAlign == '居中'?'center':'right'}">
            <i v-if="detial.required" class="required">*</i>
            <span class="txt" :style="{fontSize: detial.labelStyle.fontSize + 'px',color: detial.labelStyle.color,fontWeight: detial.labelStyle.fontWight,fontStyle: detial.labelStyle.fontStyle,textDecoration: detial.labelStyle.textDecoration}">{{detial.label}}</span>
        </div>
        <div class="cont" :class="{'ico-right': detial.inputStyle.iconSite == '居右'}">
            <template v-if="detial.isCurrent">
                <el-date-picker class="ybd_date" popper-class="ybd-cus-date" :value="new Date()" :type="detial.dataType" :placeholder="detial.placeholder" :format="currentFormat" :editable="false" :clearable="false" readonly></el-date-picker>
            </template>
            <template v-else>
                <template v-if="detial.isRang">
                    <el-date-picker class="ybd_date" popper-class="ybd-cus-date" :range-separator="detial.valueFormat == '文字'?'至':'-'" v-model="detial.value" :type="detial.rangDataType" :start-placeholder="detial.startPlaceholder" :end-placeholder="detial.endPlaceholder" :format="rangFormat" :editable="false" :clearable="false"></el-date-picker>
                </template>
                <template v-else>
                    <el-date-picker class="ybd_date" popper-class="ybd-cus-date" v-model="detial.value" :placeholder="detial.placeholder" :type="detial.dataType" :format="currentFormat" :editable="false" :clearable="false"></el-date-picker>
                </template>
            </template>

        </div>
    </div>
</template>
<script>
import $ from "jquery";
export default {
    props: {
        detial: {
            type: Object,
        },
    },
    computed: {
        currentFormat() {
            let formatStr = "";
            switch (this.detial.dataType) {
                case "date":
                    formatStr =
                        this.detial.valueFormat == "文字"
                            ? "yyyy年MM月dd日"
                            : "yyyy-MM-dd";
                    break;
                case "week":
                    formatStr =
                        this.detial.valueFormat == "文字"
                            ? "yyyy年 第WW周"
                            : "yyyy WW'th' week";
                    break;
                case "month":
                    formatStr =
                        this.detial.valueFormat == "文字"
                            ? "yyyy年MM月"
                            : "yyyy-MM";
                    break;
                case "year":
                    formatStr =
                        this.detial.valueFormat == "文字" ? "yyyy年" : "yyyy";
                    break;
                case "time":
                    formatStr =
                        this.detial.valueFormat == "文字"
                            ? "HH时mm分ss秒"
                            : "HH:mm:ss";
                    break;
                default:
                    formatStr =
                        this.detial.valueFormat == "文字"
                            ? "yyyy年MM月dd日 HH时mm分ss秒"
                            : "yyyy-MM-dd HH:mm:ss";
                    break;
            }
            return formatStr;
        },
        rangFormat() {
            let formatStr = "";
            switch (this.detial.rangDataType) {
                case "daterange":
                    formatStr =
                        this.detial.valueFormat == "文字"
                            ? "yyyy年MM月dd日"
                            : "yyyy-MM-dd";
                    break;
                default:
                    formatStr =
                        this.detial.valueFormat == "文字"
                            ? "yyyy年MM月dd日 HH时mm分ss秒"
                            : "yyyy-MM-dd HH:mm:ss";
                    break;
            }
            return formatStr;
        },
        watchAttr() {
            return {
                ...this.detial.inputStyle,
                isRang: this.detial.isRang,
                isCurrent: this.detial.isCurrent,
            };
        },
    },
    watch: {
        watchAttr: {
            handler() {
                this.$nextTick(function () {
                    const styleStr = this.detial.inputStyle;
                    $(
                        "#" +
                            this.detial.uuid +
                            " .ybd_date .el-input__inner,#" +
                            this.detial.uuid +
                            " .ybd_date.el-range-editor"
                    ).css({
                        height: styleStr.height + "px",
                        borderWidth: styleStr.borderWidth + "px",
                        borderStyle: styleStr.borderStyle,
                        borderColor: styleStr.borderColor,
                        borderRadius: styleStr.borderRadius + "px",
                        backgroundColor: styleStr.backgroundColor,
                    });
                });
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>
<style lang="less" scoped>
.item {
    display: flex;
    align-items: center;
    .label {
        max-width: 100%;
        .required {
            color: #f56c6c;
            margin-right: 4px;
        }
    }
    ::v-deep .cont {
        flex: 1;
        display: flex;
        margin: 0 0 0 10px;
        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
            width: 100%;
        }
        .el-range-editor {
            .el-range-separator {
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
            }
            .el-range-input {
                background-color: transparent;
                border-radius: inherit;
            }
        }
    }
    ::v-deep .cont.ico-right {
        .el-input--prefix .el-input__inner {
            padding-left: 15px;
        }
        .el-input__prefix {
            left: auto;
            right: 5px;
        }
        .el-date-editor {
            .el-range__icon {
                position: absolute;
                right: 5px;
            }
        }
    }
}
</style>