
<template>
	<div class="item" :id="detial.uuid" :style="{height: 'calc( 100% - ' + detial.itemStyle.interval + 'px )',borderWidth: detial.itemStyle.borderWidth + 'px',borderStyle: detial.itemStyle.borderStyle,borderColor: detial.itemStyle.borderColor,padding: detial.itemStyle.paddingVertical + 'px ' + detial.itemStyle.paddingHorizontal + 'px',marginBottom: detial.itemStyle.interval + 'px',borderRadius: detial.itemStyle.borderRadius + 'px',backgroundColor: detial.itemStyle.backgroundColor}">
		<div class="label" v-show="detial.showLabel" :style="{width: detial.labelStyle.width + 'px',textAlign: detial.labelStyle.textAlign == '居左'?'left': detial.labelStyle.textAlign == '居中'?'center':'right'}">
			<i v-if="detial.required" class="required">*</i>
			<span class="txt" :style="{fontSize: detial.labelStyle.fontSize + 'px',color: detial.labelStyle.color,fontWeight: detial.labelStyle.fontWight,fontStyle: detial.labelStyle.fontStyle,textDecoration: detial.labelStyle.textDecoration}">{{detial.label}}</span>
		</div>
		<div class="cont">
			<el-upload class="ybd_upload" ref="fileUpload" action="/api/formdesign/upload.api" :headers="{Authorization: 'Form Bearer ' + formToken}" :before-upload="beforeImgUpload" :on-exceed="masterFileMax" :limit="detial.allowNum" :file-list="detial.value" :on-success="handleImgSuccess">
				<el-button type="primary">
					<i class="el-icon-upload" v-if="detial.inputStyle.isIcon"></i>
					<span class="txt">{{detial.inputStyle.text}}</span>
				</el-button>
			</el-upload>
		</div>
	</div>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
	},
	data() {
			return {
				formToken: sessionStorage.getItem('token')?sessionStorage.getItem('token'):'',
			};
	},
	methods: {
		// 上传前的校验
		beforeImgUpload(file){
			const isLt5M = file.size / 1024 / 1024 < this.detial.allowSize;
			if (!isLt5M) {
				this.$message.error(`大小不能超过 ${this.detial.allowSize}MB!`);
			}
			return isLt5M;
		},
		masterFileMax() {
			this.$message.error(`请最多上传 ${this.detial.allowNum} 个文件。`);
		},
		// 上传成功回调
		handleImgSuccess(){
			this.detial.value = this.$refs.fileUpload.uploadFiles;
		}
	},
	watch: {
		"detial.inputStyle": {
			handler() {
				this.$nextTick(function () {
					const styleStr = this.detial.inputStyle;
					$("#" + this.detial.uuid + " .ybd_upload .el-button").css({
						width: styleStr.width + "px",
						height: styleStr.height + "px",
						borderWidth: styleStr.borderWidth + "px",
						borderStyle: styleStr.borderStyle,
						borderColor: styleStr.borderColor,
						borderRadius: styleStr.borderRadius + "px",
						backgroundColor: styleStr.backgroundColor,
						fontSize: styleStr.fontSize + "px",
						color: styleStr.color,
						fontWeight: styleStr.fontWight,
						fontStyle: styleStr.fontStyle,
					});
					$("#" + this.detial.uuid + " .ybd_upload .txt").css({
						textDecoration: styleStr.textDecoration,
					});
				});
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
<style lang="less" scoped>
.item {
	display: flex;
	.label {
		max-width: 100%;
		line-height: 40px;
		.required {
			color: #f56c6c;
			margin-right: 4px;
		}
	}
	::v-deep .cont {
		flex: 1;
		display: flex;
		margin: 0 0 0 10px;
		overflow: hidden;
		.ybd_upload {
			max-width: 100%;
			overflow: hidden;
			.el-upload {
				.el-button {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0;
					border-color: var(--theme-custom-color);
					background-color: var(--theme-custom-color);
				}
			}
		}
	}
}
</style>