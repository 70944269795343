<template>
    <div class="item" :id="detial.uuid" :style="{height: 'calc( 100% - ' + detial.itemStyle.interval + 'px )',borderWidth: detial.itemStyle.borderWidth + 'px',borderStyle: detial.itemStyle.borderStyle,borderColor: detial.itemStyle.borderColor,padding: detial.itemStyle.paddingVertical + 'px ' + detial.itemStyle.paddingHorizontal + 'px',marginBottom: detial.itemStyle.interval + 'px',borderRadius: detial.itemStyle.borderRadius + 'px',backgroundColor: detial.itemStyle.backgroundColor}">
        <div class="label" v-show="detial.showLabel" :style="{width: detial.labelStyle.width + 'px',textAlign: detial.labelStyle.textAlign == '居左'?'left': detial.labelStyle.textAlign == '居中'?'center':'right'}">
            <i v-if="detial.required" class="required">*</i>
            <span class="txt" :style="{fontSize: detial.labelStyle.fontSize + 'px',color: detial.labelStyle.color,fontWeight: detial.labelStyle.fontWight,fontStyle: detial.labelStyle.fontStyle,textDecoration: detial.labelStyle.textDecoration}">{{detial.label}}</span>
        </div>
        <div class="cont">
            <el-checkbox-group class="ybd_checkbox" :class="{horizontal: detial.inputStyle.orientationMode == '纵向'}" v-model="detial.value">
                <el-checkbox v-for="(item,index) in detial.options" :key="index" :label="index">{{item.value}}</el-checkbox>
            </el-checkbox-group>
        </div>
    </div>
</template>
<script>
import $ from "jquery";
export default {
    props: {
        detial: {
            type: Object,
        },
    },
    computed: {
        watchAttr() {
            return {
                ...this.detial.inputStyle,
                options: this.detial.options,
            };
        },
    },
    watch: {
        watchAttr: {
            handler() {
                this.$nextTick(function () {
                    const styleStr = this.detial.inputStyle;
                    if (styleStr.orientationMode == "横向") {
                        $(
                            "#" +
                                this.detial.uuid +
                                " .ybd_checkbox .el-checkbox"
                        ).css({
                            display: "inline-block",
                            margin: "12px 0",
                            marginRight: styleStr.intervalHorizontal + "px",
                        });
                    } else {
                        $(
                            "#" +
                                this.detial.uuid +
                                " .ybd_checkbox .el-checkbox"
                        ).css({
                            display: "block",
                            margin: 0,
                            marginBottom: styleStr.intervalVertical + "px",
                        });
                    }
                });
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>
<style lang="less" scoped>
.item {
    display: flex;
    align-items: center;
    .label {
        max-width: 100%;
        .required {
            color: #f56c6c;
            margin-right: 4px;
        }
    }
    .cont {
        flex: 1;
        display: flex;
        margin: 0 0 0 10px;
        .el-checkbox {
            margin: 12px 30px 12px 0;
        }
        .horizontal {
            .el-checkbox {
                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
}
</style>
